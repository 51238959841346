<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <div>
            <bread-crumbs  :items='items' />
            <tabs-comp :tabs="tabs" />
        </div>
        <v-row style="margin-top:45px;"></v-row>
        <v-row>
            <v-col cols="12" class="m-3" :style="`direction:`+lang.dir+`;`">
                <v-data-table
                :headers="headers"
                :items="tablerowsF"
                :search="search"
                :items-per-page="15"
                width="100%"
                id="MainTable"
                hide-default-footer
                hide-default-header
                fixed-header
            >
                <template slot="body.prepend">  
                    <tr>
                    <td v-for="(iHead, indHead) in headers" :key="indHead" 
                        :class="iHead.class + ` text-center `" style="border:1px solid #000 !important;"
                    >
                        {{ iHead.text }}
                    </td>
                    </tr>
                </template>
                <template v-slot:item="row">
                    <tr>
                        <td style="width:50px;text-align:center;">{{ row.item.invoice_number }}</td>
                        <td :style="`border:2px solid #fff !important;width:50px;text-align:center;` + row.item._customer_type_style">{{ row.item._customer_type }}</td>
                        <td :style='`text-align:center;`'>{{ row.item._invtype }}</td>
                        <td class="text-center" nowrap v-if="row.item.customer_type == 1">{{ row.item.full_name }}</td>
                        <td class="text-center" nowrap v-if="row.item.customer_type == 2">{{ row.item.company_name }}</td>
                        <td class="text-center" nowrap v-if="row.item.customer_type == 3">{{ row.item.entity_name }}</td>
                        <td class="text-center">{{ row.item.mobile }}</td>
                        <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.showPlate">{{ row.item.plate_number }}</td>
                        <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts || $store.state.licenseType.showPlate">{{ row.item.model }}</td>
                        <td class="text-center" nowrap>{{ row.item.inv_date.substr(0,10) }}</td>  
                        <!-- <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.total) }}</td> -->
                        <td class="text-center">{{ $convertToComa(row.item.total,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.vat,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.paid,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.cash,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.span,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center">{{ $convertToComa(row.item.remain,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center" v-if="$store.state.licenseType.showCosts">{{ $convertToComa(row.item.cost,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center" v-if="$store.state.licenseType.showProfits">{{ $convertToComa(row.item.profit,$store.state.licenseType.moneyComma) }}</td>
                        <td class="text-center" v-if="$store.state.group_id == 1 && $store.state.licenseType.showAddedBy">{{ row.item._created_by }}</td>
                        <td :style='`width:100px;text-align:center;color:#FFF !important;background:`+row.item._statusbgColor+` !important;`'>{{ row.item._status }}</td>
                        <td
                            style="
                            cursor: pointer;
                            width: 85px;
                            border-top: 1px solid #bbb !important;border-bottom: 1px solid #bbb !important;
                            "
                            class="backBlue text-center"
                        >
                        <div 
                        v-b-toggle.pview_invoice @click="getInvo(row.item)">
                        {{lang.view}}</div>
                        <!-- <v-btn style="font-size:0.2em;background:darkblue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn> -->
                        <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                        </td>
                    </tr>
                </template>

            </v-data-table>
            </v-col>
        </v-row>
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ExportsPrint from '@/components/exports-print.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import Footer from '@/components/Footer.vue'

export default{
  components: { HeaderPortrate, ExportsPrint, BreadCrumbs, TabsComp, Footer },
    data() {
    
      return {
        search: '',
        tablerowsF: [],
      }  
    },
    computed: {
        lang: function()
        {
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }, 
        items: function()
        {
            return {
                text: this.lang.invoices,
                disabled: true,
                href: '/invoicelist/',
            }
        },
        headers: function()
        {
            let t = [];
            t.push(
                {
                    text: "", align: 'center',
                    filterable: true, value: 'mobile',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "اسم المندوب", align: 'center',
                    filterable: true, value: 'agent_name',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "النسبة", align: 'center',
                    filterable: true, value: 'percentage',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "عملاء جدد", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "استلم ديمو", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "عرض بنرامج", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "برنامج مع جهاز", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "تواصل مع العميل", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "تم الشراء", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "لم يتم الشراء", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "العمولة المستحقة", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            t.push(
                {
                    text: "العمولة الممكنة", align: 'center',
                    filterable: true, value: 'new_customers',
                    sortable: false, class: 'backBlack', perm: 'any'
                }
            );
            return t;
        },
        tabs: function(){
        // console.log(this.$store.getters['state'].licenseType.activemenu);
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
              const cM = localStorage.getItem('currentMenu');
              // console.log("cM",cM);
              if(value.main.index == cM){
                  cMenu = value.sub;
              }else if(cM == null && value.main.index == 1){
                  cMenu = value.sub;
              }
            });
           
            let t = []
            // console.log("cMenu",cMenu);
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    }
}
</script>
